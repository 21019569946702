import {
  appendMerchant,
  getMerchant,
  NotifyRemove,
  NotifySet,
  removeMerchant,
} from "@/api";
import { message } from "ant-design-vue";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SettingView extends Vue {
  @Ref("form-upload") formUpload: any;
  ModalText = "Content of the modal";
  visible = false;
  confirmLoading = false;
  spinning = false;

  form: any = {
    mchid: "",
    cert_serial_no: "",
    apiv3_key: "",
    appid: "",
    private_key: null,
    remarks: "",
  };

  rules = {
    mchid: { required: true },
    cert_serial_no: { required: true },
    apiv3_key: { required: true },
    appid: { required: true },
    private_key: { required: true },
    remarks: { required: true },
  };

  columns = [
    {
      title: "mchid",
      dataIndex: "mchid",
      key: "mchid",
      ellipsis: true,
    },
    {
      title: "remarks",
      dataIndex: "remarks",
      key: "remarks",
      ellipsis: true,
    },
    {
      title: "操作",
      key: "action",
      scopedSlots: { customRender: "action" },
    },
  ];

  data = [];

  handleModal = false;
  handleItem = {};

  labelCol = { span: 8 };
  wrapperCol = { span: 14 };

  mounted() {
    this.getMerchant();
  }

  disabledDate(current: any) {
    return current > moment().endOf("day");
  }

  showHandleModal(item: any) {
    this.handleModal = true;
    this.handleItem = item;
  }
  handleOk(e: any) {
    // console.log(e);
    this.handleModal = false;
  }

  remove(record: any) {
    // console.log(record);
    this.$confirm({
      title: "确定要删除该账号吗?",
      okText: "确定",
      cancelText: "取消",
      onOk: async () => {
        const res: any = await removeMerchant(record.id);
        if (res.data.code === 200) {
          message.success("删除成功");
          this.getMerchant();
        } else {
          message.error("服务器异常");
        }
      },
    });
  }

  // 获取商户信息
  async getMerchant() {
    const res: any = await getMerchant();
    // console.log(res);
    const data = res.data.data || [];
    this.data = data.map((item: any, index: number) => {
      return {
        key: index,
        ...item,
      };
    });
  }

  showModal() {
    this.visible = true;
  }

  handleCancel(e: any) {
    this.visible = false;
  }

  // 添加新商户
  addNewMerchant() {
    this.showModal();
  }

  async beforeUpload(file: File) {
    return true;
  }

  async customRequest(option: any) {
    this.formUpload.sFileList = [];
    this.form.private_key = option.file;
    setTimeout(() => {
      option.onSuccess();
    }, 0);
  }

  async handleAddOk(e: any) {
    // console.log(this.form);

    this.confirmLoading = true;
    const res: any = await appendMerchant(this.form);
    // console.log(res);
    if (res.data.code === 200) {
      this.$message.success("添加成功");
      this.getMerchant();
      this.visible = false;
    } else {
      this.$message.error("服务器异常");
    }

    this.confirmLoading = false;
  }

  // 启用邮箱通知
  async setEmail(record: any) {
    const url =
      "https://" + location.host + "/api/complaint/notify/" + record.mchid;

    this.spinning = true;
    const res: any = await NotifySet({ mchid: record.mchid, url });
    // console.log(res);
    this.spinning = false;
    if (res.data.data.code === 200) {
      this.getMerchant();
      this.$message.success("设置成功");
    } else {
      this.$message.error(res.data.data.message);
    }
  }

  // 取消邮箱通知
  async removeEmail(record: any) {
    this.spinning = true;
    const res: any = await NotifyRemove({ mchid: record.mchid });
    // console.log(res);
    this.spinning = false;
    if (res.data.data.code === 204) {
      this.getMerchant();
      this.$message.success("取消成功");
    } else {
      this.$message.error(res.data.data.message);
    }
  }
}
